// Импорт необходимых функций из Vue.js
import {createApp, reactive, toRefs} from 'vue'
import {showAlert} from "./app";

// Определение селектора для корневого элемента приложения
const appQuery = '#vueModalCart'
const appContainer = document.querySelector(appQuery)

// Основной компонент корзины
const modalCartComponent = {
    props: {
        cartsJSON: String,
        cartCount: Number,
        subtotal: Number,
        tax: Number,
        total: Number,
        shipping: Number
    },
    setup(props) {
        // Инициализация реактивного состояния
        const state = reactive({
            carts: JSON.parse(props.cartsJSON) || [],
            cartCount: props.cartCount,
            subtotal: props.subtotal,
            tax: props.tax,
            total: props.total,
            shipping: props.shipping,
            route_update_cart: AIZ.local.route_update_cart,
            route_remove_from_cart: AIZ.local.route_remove_from_cart
        });

        // Форматирование валюты
        const formatCurrency = (value) => {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'EUR'
            }).format(value);
        };

        // Добавление товара в корзину
        function addToCart(id, productID, price, quantity, discount, productData) {
            const cart = state.carts.find(cart => cart.id === id)
            if (cart) {
                cart.quantity = quantity
                cart.price = price
                cart.discount = discount
            } else {
                state.carts.push({
                    id,
                    productID,
                    price,
                    quantity,
                    discount,
                    product: productData
                })
            }
        }

        // Обновление данных корзины
        function update(cartCount, subtotal, tax, total) {
            $('#modalCartButton').find('span>span')[0].innerHTML = total
            state.cartCount = cartCount
            state.subtotal = subtotal
            state.tax = tax
            state.total = total
        }

        // Увеличение количества товара
        function increaseQuantity(cart) {
            cart.quantity++
            updateCart(cart)
        }

        // Уменьшение количества товара
        function decreaseQuantity(cart) {
            if (cart.quantity > 1) {
                cart.quantity--
                updateCart(cart)
            } else {
                removeFromCart(cart)
            }
        }


        // Обновление корзины на сервере
        function updateCart(cart) {
            $.post(state.route_update_cart, {
                _token: AIZ.data.csrf,
                id: cart.id,
                quantity: cart.quantity
            }, function (response) {
                if (response.status === 1) {
                    update(response.cart_count, response.subtotal, response.tax, response.total)
                }
            })
        }

        // Удаление товара из корзины
        function removeFromCart(cart) {
            state.carts = state.carts.filter(c => c.id !== cart.id)
            $.post(state.route_remove_from_cart, {
                _token: AIZ.data.csrf,
                id: cart.id
            }, function (response) {
                if (response.status === 1) {
                    update(response.cart_count, response.subtotal, response.tax, response.total)
                }
            })
        }


        function findProductById(productID) {
            return state.carts.find(cart => cart.productID === +productID || cart.product_id === +productID)
        }

        function increaseQuantityById(productID) {
            const product = findProductById(productID)
            if (product)
                increaseQuantity(product)
        }

        function decreaseQuantityById(productID) {
            const product = findProductById(productID)
            if (product)
                decreaseQuantity(product)
        }

        function checkForProductInCart(productID) {
            const product = findProductById(productID)
            if (product)
                return product.quantity
        }

        return {
            ...toRefs(state),
            formatCurrency,
            addToCart,
            update,
            increaseQuantity,
            decreaseQuantity,
            updateCart,
            removeFromCart,
            decreaseQuantityById,
            increaseQuantityById,
            checkForProductInCart
        }
    }
}

// Инициализация props для компонента
const props = {
    cartsJSON: appContainer.dataset.cartsJson,
    cartCount: parseInt(appContainer.dataset.cartCount),
    subtotal: parseInt(appContainer.dataset.subtotal),
    tax: parseInt(appContainer.dataset.tax),
    total: parseInt(appContainer.dataset.total),
    shipping: 0
}

// Создание и монтирование Vue приложения
const modalCartApp = createApp(modalCartComponent, props).mount(appQuery)

// Функция добавления товара в корзину
function addToCart(productID, amount, element, callback = null) {
    const authenticated_and_customer = AIZ.local.authenticated
    const route_add_to_cart = AIZ.local.route_add_to_cart
    const translate_please_login = AIZ.local.translate_please_login
    const translate_added_to_cart = AIZ.local.translate_added_to_cart

    if (!authenticated_and_customer) {
        showAlert(translate_please_login, 'warning')
        return false
    }

    $.post(route_add_to_cart, {
        _token: AIZ.data.csrf,
        product_id: productID,
        amount
    }, function (response) {
        if (response.status === 1) {
            if (callback) callback()
            modalCartApp.addToCart(response.id, response.product_id, response.price, response.quantity, response.discount, response.product)
            modalCartApp.update(response.quantity, response.subtotal, response.tax, response.total)
            showAlert(translate_added_to_cart)
        } else {
            showAlert(response.error, 'warning')
        }
    })
}

// Инициализация триггеров добавления в корзину
export function addAddToCartTrigger() {
    const enableCartQty = document.querySelectorAll('.js-add-to-cart-toggle')

    function addAddToCartTriggerToInput(container, show, hide) {
        const input = container.querySelector('input')
        if (!input) return

        input.addEventListener('hideCounter', function (e) {
            if (show) show.classList.remove('d-none')
            if (hide) hide.classList.add('d-none')
        })

        input.addEventListener('keypress', function (e) {
            if (e.key === 'Enter') {
                const amount = input.value
                const callback = function () {
                    if (show) show.classList.remove('d-none')
                    if (hide) hide.classList.add('d-none')
                }
                addToCart(input.dataset.productId, amount, input, callback)
            }
        })
    }

    enableCartQty.forEach(function (ecqElement) {
        const container = ecqElement.parentNode
        const cartQtyWrap = container.querySelector('.js-add-to-cart')
        const input = cartQtyWrap.querySelector('input')

        const productId = input.dataset.productId

        if (cartQtyWrap) {
            const productCount = modalCartApp.checkForProductInCart(productId)
            if (productCount) {
                ecqElement.classList.add('d-none')
                cartQtyWrap.classList.remove('d-none')
                input.value = productCount
            }
            ecqElement.addEventListener('click', function () {
                const amount = input.value

                this.classList.add('d-none')
                cartQtyWrap.classList.remove('d-none')
                addToCart(productId, amount, input)
            })

        }

        addAddToCartTriggerToInput(cartQtyWrap, ecqElement, cartQtyWrap)
    })

    document.querySelectorAll('.cardImageOverlay').forEach(function (overlay) {
        addAddToCartTriggerToInput(overlay, null, overlay)
    })
}

// Инициализация слушателей для изменения количества товаров
export function addCartQtyInputListeners() {
    document.querySelectorAll('.qtyInputGroup').forEach(function (group) {
        const qtyDecrease = group.querySelector('.qtyDecrease')
        const qtyEncrease = group.querySelector('.qtyEncrease')
        const qtyInput = group.querySelector('.qtyInput')
        const qtyInputMin = parseInt(qtyInput.getAttribute('min'))
        const qtyInputMax = parseInt(qtyInput.getAttribute('max'))

        qtyDecrease.addEventListener('click', function (e) {
            const qtyInputValue = parseInt(qtyInput.value)
            const productId = qtyInput.dataset.productId
            modalCartApp.decreaseQuantityById(productId)
            if (qtyInputValue > qtyInputMin) {
                qtyInput.value = qtyInputValue - 1
            } else if (this.classList.contains('hideCardImageOverlay')) {
                this.closest('.cardImageOverlay').classList.add('d-none')
            } else if (this.classList.contains('disableCardQty')) {
                const event = new Event('hideCounter')
                qtyInput.dispatchEvent(event);
            }
        })

        qtyEncrease.addEventListener('click', function (e) {
            const qtyInputValue = parseInt(qtyInput.value)
            const productId = qtyInput.dataset.productId
            if (qtyInputValue < qtyInputMax) {
                qtyInput.value = qtyInputValue + 1
                modalCartApp.increaseQuantityById(productId)
            }
        })
    })
}

// Инициализация функциональности
// сработает один раз при загрузе страницы
addAddToCartTrigger()
addCartQtyInputListeners()
