// General
// -------

// # Vue.js general imports

// Plugins
// -------
// # Frontend Plugins
// ## var window.Popper required by popper.js
// ## var bootstrap & window.bootstrap required by bootstrap
// ## niceSelect2 (.lpNiceSelect2)
// ===================================================
import NiceSelect from 'nice-select2'
import splide from '@splidejs/splide'
import "./cart"
import {addAddToCartTrigger, addCartQtyInputListeners} from "./cart";

const lpNiceSelect2 = document.querySelectorAll('.lpNiceSelect2')

$(document).ready(function () {
    lpNiceSelect2.forEach(function (item) {
        const optionsData = item.dataset.nsoptions

        if (optionsData) {
            new NiceSelect(item, JSON.parse(optionsData))
        } else {
            new NiceSelect(item)
        }

        $(item).addClass('nice-select-loaded')
    })
})

// ## Read More (.js-read-smore)
// ===================================================

// import ReadSmore from 'read-smore'
// var readMores = document.querySelectorAll(".js-read-smore")
// var rmOptions = {
//     blockClassName: "read-more",
//     moreText: "Подробнее",
//     lessText: "Свернуть",
// }
// ReadSmore(readMores, rmOptions).init()


// ## Bootstrap Tooltips (data-bs-toggle="tooltip")
// ===================================================

function addTooltipTriggers() {
    const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
    )

    _toConsumableArray(tooltipTriggerList).map(
        function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        }
    )
}

addTooltipTriggers()

// ## Carousels: Splide (.splide)
// ====================================================

const carousels = document.querySelectorAll('.splide')
carousels.forEach(function (item) {
    new splide(item).mount()
})


// # Backend Plugins

// ## var _ & window._ required by laravel-mix (Lodash)

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
// ## var jQuery & window.jQuery & $ & window.$
// ##  required by laravel-mix

// ## Axios
// ## (var axios & window.axios included by laravel-mix)

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = document.querySelector('meta[name="csrf-token"]')

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}


// General Functions & Elements
// ----------------------------

// # Bootstrap Alert powered by
// # showAlert(message, type, timeout)
// ==========================================================================================

let alertActive = false
let alertQueue = 0

export function showAlert(message, type, timeout) {
    // Alert queue increases
    alertQueue += 1
    // Alert is active
    alertActive = true

    // Set default timeout if not given
    if (!timeout) timeout = 3000

    if (alertQueue > 1 && alertActive) {
        // Queue alert
        alertQueue -= 1
        setTimeout(function () {
            alertActive = false
            showAlert(message, type, timeout)
        }, timeout * (alertQueue - 1))
    }

    // Set alert type if given
    if (type) {
        $('#alert').removeClass('alert-success')
        $('#alert').addClass('alert-' + type)
    }

    // Show alert and transition
    $('#alert').removeClass('d-none')
    $('#alert').html(message).css('opacity', '1')

    // Hide alert after timeout and transition
    const transitionDuration = 500
    setTimeout(function () {
        $('#alert').css('opacity', '0')

        setTimeout(function () {
            $('#alert').addClass('d-none')

            // Alert queue decreases
            alertQueue -= 1
            // Alert inactive
            if (alertQueue === 0) {
                alertActive = false
            }

        }, transitionDuration)
    }, timeout)
}


// # Copy to Clipboard (.copyToClipboard)
// ===================================================

function copyText(element) {
    const textToCopy = element.innerText
    const myTemporaryInputElement = document.createElement('input')
    myTemporaryInputElement.type = 'text'
    myTemporaryInputElement.value = textToCopy
    document.body.appendChild(myTemporaryInputElement)
    myTemporaryInputElement.select()
    document.execCommand('Copy')
    document.body.removeChild(myTemporaryInputElement)
}

const copyToClipboard = document.querySelectorAll('.copyToClipboard')
copyToClipboard.forEach(function (button) {
    const copyTarget = button.previousElementSibling
    const tooltip = new bootstrap.Tooltip(button)
    button.addEventListener('click', function () {
        copyText(copyTarget)
        tooltip.show()
        setTimeout(function () {
            tooltip.hide()
        }, 3000)
    })
})


// # Toggle Class (.lpToggler)
// ===================================================

const classToggler = document.querySelectorAll('.lpToggler')
classToggler.forEach(function (item) {
    item.addEventListener('click', function () {
        const classToToggle = item.dataset.classToggle
        item.classList.toggle(classToToggle)
    })
})

// # Countdown Timers (.dateCountdownContainer)
// =======================================================================

const dateCountdownContainer = document.querySelectorAll(
    '.dateCountdownContainer'
)
dateCountdownContainer.forEach(function (item) {
    const dayField = item.querySelector('.dayField')
    const hourField = item.querySelector('.hourField')
    const minuteField = item.querySelector('.minuteField')
    const secondField = item.querySelector('.secondField')
    let interval
    const second = 1000
    const minute = second * 60
    const hour = minute * 60
    const day = hour * 24
    let eventDay = new Date()
    eventDay = new Date(eventDay.getTime() + 7 * day)

    const countDownFn = function countDownFn() {
        const today = new Date()
        const timeSpan = eventDay - today

        if (timeSpan <= -today) {
            clearInterval(interval)
        } else if (timeSpan <= 0) {
            clearInterval(interval)
        } else {
            const days = Math.floor(timeSpan / day)
            const hours = Math.floor((timeSpan % day) / hour)
            const minutes = Math.floor((timeSpan % hour) / minute)
            const seconds = Math.floor((timeSpan % minute) / second)
            dayField.innerHTML = days
            hourField.innerHTML = hours
            minuteField.innerHTML = minutes
            secondField.innerHTML = seconds
        }
    }

    interval = setInterval(countDownFn, second)
})


// # Product Card Overlay (.productCard)
// ===================================================

function addCardOverlayTrigger() {
    console.log("addCardOverlayTrigger")
    const productCard = document.querySelectorAll('.productCard')

    if (productCard) {
        productCard.forEach(function (card) {
            const cardImageOverlay = card.querySelector('.cardImageOverlay')
            const showCardImageOverlay = card.querySelector('.showCardImageOverlay')

            if (showCardImageOverlay) {
                showCardImageOverlay.addEventListener('click', function () {
                    cardImageOverlay.classList.remove('d-none')
                })
            }
        })
    }
}

addCardOverlayTrigger()


// # Radio Toggle (.showTarget, .hideTarget)
// =======================================================================

const showTarget = document.querySelectorAll('.showTarget')
const hideTarget = document.querySelectorAll('.hideTarget')

if (hideTarget) {
    hideTarget.forEach(function (item) {
        item.addEventListener('change', function () {
            const target = document.getElementById(item.dataset.target)

            if (item.checked) {
                target.classList.remove('show')
                target.classList.add('hide')
            }
        })
    })
}

if (showTarget) {
    showTarget.forEach(function (item) {
        item.addEventListener('change', function () {
            const target = item.dataset.target

            if (item.checked) {
                document.getElementById(target).classList.add('show')
            }
        })
    })
}


// # Range Slider (.rangeSlider)
// =======================================================================

const rangeSlider = document.querySelectorAll('.rangeSlider')

if (rangeSlider) {
    rangeSlider.forEach(function (el) {
        new (rangeSlider())(el, {
            outputFormatter: function outputFormatter(output) {
                return '&euro'.concat(output);
            }
        })
    })
}


// # Radio Switch ([data-radio-switch] [type="radio"])
// =======================================================================

const radioSwitch = document.querySelectorAll(
    '[data-radio-switch] [type="radio"]'
)

if (radioSwitch) {
    radioSwitch.forEach(function (item) {
        item.addEventListener('click', function () {
            const target = document.getElementById(item.dataset.target)
            const targetSiblings = getSiblings(target)
            target.classList.remove('d-none')
            targetSiblings.forEach(function (sibling) {
                sibling.classList.add('d-none')
            })
        })
    })
}


// Header

// # Nav on Scroll (#headerTop, #headerFixed)
// ===================================================

let scrollPosition = window.scrollY

const header = document.getElementById('header')
const headerHeight = header.offsetHeight
const headerTop = document.getElementById('headerTop')
const headerFixed = document.getElementById('headerFixed')
const headerFixedHeight = headerFixed.offsetHeight
let headerFixedTop = headerFixed.getBoundingClientRect().top

const alert = document.getElementById('alert')
const alertTopDefault = alert.getBoundingClientRect().y

window.addEventListener('scroll', function () {
    const mql = window.matchMedia('(min-width: 992px)')
    headerFixedTop = headerFixed.getBoundingClientRect().top

    scrollPosition = window.scrollY
    headerFixed.classList.add('position-fixed')

    if (scrollPosition > headerFixedTop && mql.matches) {
        alert.style.top = '' +
            (alertTopDefault + headerFixedHeight) + 'px'

        headerTop.style.marginBottom = '' +
            headerFixedHeight + 'px'
    } else {
        alert.style.top = alertTopDefault

        headerFixed.classList.remove('position-fixed')
        headerTop.style.marginBottom = '0'
    }

    if (scrollPosition >= headerHeight - headerFixedHeight && mql.matches) {
        headerFixed.classList.add('showBottom')
    } else {
        headerFixed.classList.remove('showBottom')
    }
})


// # Mega Menu (.lp-sub-menu-*, .megaMenu*)
// =======================================================================

const getSiblings = function getSiblings(elem) {
    const siblings = []
    let sibling = elem.parentNode.firstChild

    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            siblings.push(sibling)
        }

        sibling = sibling.nextSibling
    }

    return siblings
}

if (window.innerWidth > 992) {
    const megaMenu = document.getElementById('megaMenu')

    if (megaMenu) {
        const megaMenuCategoriesLinks = megaMenu.querySelectorAll(
            '.lp-sub-menu-toggle'
        )
        const megaMenuCategoriesWrap = megaMenu.querySelector(
            '.megaMenuCategoriesWrap'
        )
        const lpMegaMenuCategoryListing = megaMenu.querySelectorAll(
            '.lp-megaMenu-category-listing'
        )
        lpMegaMenuCategoryListing.forEach(function (el) {
            el.style.left = megaMenuCategoriesWrap.offsetWidth + 12 + 'px'
            el.style.width =
                'calc(100% - ' +
                (megaMenuCategoriesWrap.offsetWidth + 24) +
                'px)'
        })

        // Функция для установки активной категории
        const toActiveCategory = (item) => {
            item.classList.add('is-active');
            const itemSib = getSiblings(item.parentNode);
            itemSib.forEach(function (el) {
                const link = el.querySelector('a');
                link.classList.remove('is-active');
            });
        };

        // Проверка на наличие активных категорий и установка первой категории активной, если ни одна не активна
        const hasActiveCategory = Array.from(megaMenuCategoriesLinks).some(item => item.classList.contains('is-active'));
        if (!hasActiveCategory && megaMenuCategoriesLinks.length > 0) {
            toActiveCategory(megaMenuCategoriesLinks[0]);
        }

        megaMenuCategoriesLinks.forEach(function (item) {
            item.addEventListener('mouseover', function () {
                toActiveCategory(item);
            });
        });
    }
}


// # Offcanvas Nav (#offcanvasNav)
// =======================================================================

const offcanvasNav = document.getElementById('offcanvasNav')

if (offcanvasNav) {
    offcanvasNav.addEventListener('show.bs.offcanvas', function () {
        header.classList.add('is-open')
    })
    offcanvasNav.addEventListener('hide.bs.offcanvas', function () {
        header.classList.remove('is-open')
    })
}

// # Global Search
// (#globalSearch, #searchAutocomplete)
// (.search-preloader, #search-content)
// ===================================================

const gs = '#globalSearch'
const sa = '#searchAutocomplete'
const sp = '.search-preloader'
const sc = '#search-content'
if (searchAutocomplete) {
    const search = function search() {
        const searchKey = $('#globalSearch').val()
        if (searchKey.length > 0) {
            $(sa).removeClass('d-none')
            $(sp).removeClass('d-none')
            $.post(
                '/ajax-search',
                {
                    _token: AIZ.data.csrf,
                    search: searchKey
                },
                function (data) {
                    if (data === '0') {
                        $(sc).html(null)
                        $(sc + ' .search-nothing')
                            .removeClass('d-none')
                            .html(
                                'Sorry, nothing found for <strong>"' +
                                searchKey +
                                '"</strong>'
                            )
                        $(sp).addClass('d-none')
                    } else {
                        $(sc + ' .search-nothing')
                            .addClass('d-none')
                            .html(null)
                        $(sc).html(data)
                        $(sp).addClass('d-none')
                        addCardOverlayTrigger()
                        addAddToCartTrigger()
                        addCartQtyInputListeners()
                        addWishlistTrigger()
                    }
                }
            )
        } else {
            $('#searchAutocomplete').addClass('d-none')
        }
    }
    $(gs).on('keyup', function () {
        search()
    })
    $(gs).on('focus', function () {
        search()
    })
}


// # Login Form in Modal (#loginForm)
// ===================================================

$('#loginForm').on('submit', function (e) {
    e.preventDefault()

    $.ajax({
        url: $(this).attr('action'),
        type: 'POST',
        dataType: 'json',
        data: $(this).serialize() + '&_token=' + AIZ.data.csrf,
        success: function (response) {
            // Display success message
            showAlert(response.message)
            // Redirect on success
            window.location.href = response.redirect
        },
        error: function (response) {
            // Display error messages
            const errors = response.responseJSON.errors
            const errorMessages = Object.keys(errors).map(function (key) {
                // Get the first error message for each field
                return errors[key][0]
            }).join('\n')
            showAlert('Error: ' + errorMessages, 'warning')
        }
    })
})


// # Register Form in Modal (#registrationForm)
// ===================================================

$('#registrationForm').submit(function (e) {
    e.preventDefault()
    $.ajax({
        url: $(this).attr('action'),
        type: 'POST',
        data: $(this).serialize() + '&_token=' + AIZ.data.csrf,
        success: function (response) {
            // Display success message
            showAlert(response.message)
            // Redirect on success
            window.location.href = response.redirect
        },
        error: function (response) {
            // Display error messages
            const errors = response.responseJSON.errors
            const errorMessages = Object.keys(errors).map(function (key) {
                // Get the first error message for each field
                return errors[key][0]
            }).join('\n')
            showAlert('Error: ' + errorMessages, 'warning')
        }
    })
})


// # Profile Logout in Modal (#customerLogout)
// ===================================================

$('#customerLogout').click(function (e) {
    e.preventDefault()
    $.ajax({
        url: $(this).attr('href'),
        type: 'POST',
        data: {_token: AIZ.data.csrf},
        success: function (response) {
            // Display success message
            showAlert(response.message)
            // Redirect on success
            window.location.href = response.redirect
        }
    })
})

// # Seller Register Form in Modal (#sellerRegistrationForm)
// ===================================================

$('#sellerRegistrationForm').submit(function (e) {
    e.preventDefault()
    $.ajax({
        url: $(this).attr('action'),
        type: 'POST',
        data: $(this).serialize() + '&_token=' + AIZ.data.csrf,
        success: function (response) {
            // Display success message
            showAlert(response.message)
            // Redirect on success
            window.location.href = response.redirect
        },
        error: function (response) {
            // Display error messages
            const errors = response.responseJSON.errors
            const errorMessages = Object.keys(errors).map(function (key) {
                // Get the first error message for each field
                return errors[key][0]
            }).join('\n')
            showAlert('Error: ' + errorMessages, 'warning')
        }
    })
})


// # Language (#lang-change)
// ===================================================

document.addEventListener('DOMContentLoaded', function () {
    const selectID = '#lang-change'
    const optionsSelector = selectID + ' + .nice-select .option:not(.disabled)'
    console.log("change 1")
    $(document).on('change', selectID, function (e) {
        console.log("change 2 ")
        const locale = e.target.value
        $.post('/language', {_token: AIZ.data.csrf, locale}, function () {
            location.reload()
        })
    })
})


// `main` Content

// # Read More (Vanilla JS)
// ==========================================================================================

const readMores = document.querySelectorAll('.js-read-more');
readMores.forEach(function (readMore) {
    const target = readMore.querySelector('pre')
    const targetWords = parseInt(readMore.dataset.readMoreWords)
    const text = target.innerHTML
    const disabled = target.innerHTML.split(' ').length <= targetWords
    const readMoreLink = readMore.querySelector('a')

    if (disabled) {
        readMoreLink.style.display = 'none'
    } else {
        const shortText = target.innerHTML.split(' ').slice(0, targetWords).join(' ') + '...'

        target.innerHTML = shortText
        const readMoreText = readMoreLink.innerHTML
        const readLessText = readMoreLink.dataset.readLess

        readMoreLink.addEventListener('click', function () {
            if (this.dataset.clicked === 'false') {
                target.innerHTML = text
                this.dataset.clicked = true
                const readLessLink = readMoreLink
                readLessLink.innerHTML = readLessText
                readLessLink.id = 'readLessLink'
            } else {
                target.innerHTML = shortText
                this.dataset.clicked = false
                readMoreLink.innerHTML = readMoreText
                readMoreLink.id = 'readMoreLink'
            }
        })
    }
})

// # Add to Wishlist (.js-add-to-wishlist)
// ===================================================

function checkWishlist(product_id, element) {

    const route_check_wishlist = AIZ.local.route_check_wishlist

    $.post(route_check_wishlist, {
        _token: AIZ.data.csrf,
        product_id

    }, function (response) {
        if (response.status === true) {
            element.classList.add('is-added')
            return true
        } else {
            element.classList.remove('is-added')
            return false
        }
    })
}

// Whether customer is authenticated
const authenticated =
    AIZ.local.authenticated
// 'Please login' message
const translate_please_login =
    AIZ.local.translate_please_login

// Route to add to wishlist
const route_add_to_wishlist =
    AIZ.local.route_add_to_wishlist

function addToWishlist(product_id, element) {
    // Check if customer is logged in
    if (!authenticated) {
        showAlert(translate_please_login, 'warning')
        return false
    }

    $.post(route_add_to_wishlist, {

            _token: AIZ.data.csrf,
            product_id
        },
        function (response) {

            if (response.status === true) {
                element.classList.add('is-added')
                showAlert(response.message)

            } else {
                element.classList.remove('is-added')
                showAlert(response.message, 'warning')
            }
        })
}

// Route to remove from wishlist
const route_remove_from_wishlist =
    AIZ.local.route_remove_from_wishlist

function removeFromWishlist(product_id, element) {
    // Check if customer is logged in
    if (!authenticated) {
        showAlert(translate_please_login, 'warning')
        return false
    }

    $.post(route_remove_from_wishlist, {

            _token: AIZ.data.csrf,
            product_id
        },
        function (response) {

            if (response.status === true) {
                element.classList.remove('is-added')
                showAlert(response.message)

            } else {
                element.classList.add('is-added')
                showAlert(response.message, 'warning')
            }
        })
}

function addWishlistTrigger() {
    const addToWishlistButtons = document.querySelectorAll(
        '.js-add-to-wishlist'
    )
    // Activate wishlist button's functionality
    addToWishlistButtons.forEach(function (element) {

        // Check if product is already in wishlist
        const product_id = element.dataset.productId
        checkWishlist(product_id, element)

        // Add or remove from wishlist on click
        element.addEventListener('click', function (e) {

            if (element.classList.contains('is-added')) {
                removeFromWishlist(
                    element.dataset.productId,
                    element
                )
            } else {

                addToWishlist(
                    element.dataset.productId,
                    element
                )
            }
        })
    })
}

addWishlistTrigger()


// # Newsletter Subscription (#newsletterSubscriptionForm)
// =================================================
const newsletterSubscriptionForm = document.getElementById('newsletterSubscriptionForm')
$(newsletterSubscriptionForm).on('submit', function (e) {
    e.preventDefault()
    $.ajax({
        url: $(this).attr('action'),
        type: 'POST',
        data: $(this).serialize() + '&_token=' + AIZ.data.csrf,
        success: function (response) {
            showAlert(response.message)
            newsletterSubscriptionForm.querySelector('[name="email"]').value = ''
        },
        error: function (response) {
            const error = response.responseJSON.error
            showAlert('Error: ' + error, 'warning')
        }
    })
})

// Shop Page

// # Products Load & Filter (shop_details/pane-products,
// # category/products)
// =======================
const productsFilter = document.getElementById('productsFilter')
const productsContainer = document.getElementById('productsContainer')
const loadProducts = function (url, scrollTo) {
    if (productsFilter && productsContainer) {
        $.ajax({
            url: url ? url : productsFilter.attributes.action.value,
            type: 'POST',
            data: $(productsFilter).serialize() + '&_token=' + AIZ.data.csrf,
            beforeSend: function () {
                $('#spinner').show()

                if (scrollTo) {
                    const element = productsFilter
                    const yOffset = -(headerFixed.offsetHeight)
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

                    window.scrollTo({top: y, behavior: 'smooth'})
                }

                productsContainer.innerHTML = ''
            },
            success: function (response) {
                $('#spinner').hide()

                productsContainer.innerHTML = response
                addWishlistTrigger()
                addCartQtyInputListeners()
                addCardOverlayTrigger()
                addTooltipTriggers()
                addAddToCartTrigger()

                const productsPagination = document.getElementById('productsPagination')

                if (productsPagination) {
                    const pageLinks = productsPagination.querySelectorAll('li.page-item a.page-link')
                    pageLinks.forEach(function (link) {
                        link.addEventListener('click', function (e) {
                            e.preventDefault()

                            let url = undefined

                            if (e.target.tagName === 'svg') {
                                url = e.target.parentElement.attributes.href.value
                            } else if (e.target.tagName === 'use') {
                                url = e.target.parentElement.parentElement.attributes.href.value
                            } else {
                                url = e.target.attributes.href.value
                            }
                            loadProducts(url, true)
                        })
                    })
                }
            },
            error: function (response) {
                const error = response.responseJSON.error
                showAlert('Error: ' + error, 'warning')
            }
        })
    }
}
loadProducts()
if (productsFilter) {
    productsFilter.addEventListener('change', function () {
        loadProducts()
    })
}


function addShareFacebookListener() {

    const sayThanksButton = document.getElementById('sayThanksButton');

    if (sayThanksButton) {
        sayThanksButton.addEventListener('click', function (event) {
            event.preventDefault();

            // Открываем окно добавления социального шера для Facebook
            const url = window.location.href; // Текущий URL страницы
            const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

            // Открываем новое окно для шера
            window.open(facebookShareUrl, '_blank', 'width=600,height=400');
        });
    }
}

addShareFacebookListener()
